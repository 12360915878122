import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Icon from 'shared-components/src/components/Icon';

import styles from './PopupLayout.module.css';
import {
  PopupLayoutType,
  PopupLayoutVariant,
} from '../../features/popups/popups.constants';

interface PopupLayoutProps {
  children: ReactNode | ((args: { exitPopup: () => void }) => ReactNode);
  variant?: PopupLayoutVariant;
  onClose?: () => void;
  hasBackdrop?: boolean;
  hasClose?: boolean;
  /**
   * @deprecated DO NOT USE: with new popups. Component should be isolated and have no "dangerous" in-place styles
   */
  className?: string;
  type?: PopupLayoutType;
  /**
   * @deprecated DO NOT USE: with new popups. Use PopupLayout.Header instead
   */
  title?: string;
  isRouteChanged?: boolean;
  isBackDropCloseButtonVisible?: boolean;
}

function PopupLayout({
  title,
  type = PopupLayoutType.Modal,
  children,
  hasBackdrop = true,
  onClose,
  hasClose = true,
  variant = PopupLayoutVariant.Default,
  className,
  isRouteChanged,
  isBackDropCloseButtonVisible,
}: PopupLayoutProps): JSX.Element {
  const backdropElement = useRef<HTMLDivElement | null>(null);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (isRouteChanged && onClose) {
      onClose();
    }
  }, [onClose, isRouteChanged]);

  const childProps = useMemo(() => {
    return { exitPopup: () => setIsMounted(false) };
  }, []);

  const handleBackdropClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (hasClose && backdropElement.current === e.target) {
      e.stopPropagation();
      setIsMounted(false);
    }
  };

  const handleEscKeyDown: KeyboardEventHandler = (e) => {
    if (hasClose && e.key.toLowerCase() === 'escape') {
      e.stopPropagation();
      setIsMounted(false);
    }
  };

  const handleBackdropBtnClick = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsMounted(false);
  };

  const isHeadlessVariant = variant === PopupLayoutVariant.Headless;

  return (
    <FocusLock autoFocus returnFocus>
      <div
        className={cx(styles.root, {
          [styles.rootBackdrop]: hasBackdrop,
          [styles.rootHeadless]: isHeadlessVariant,
        })}
        onMouseDown={handleBackdropClick}
        ref={backdropElement}
        onKeyDown={handleEscKeyDown}
      >
        {isBackDropCloseButtonVisible && (
          <div className={styles.backDropCloseBtn}>
            <Icon
              onClick={handleBackdropBtnClick}
              name="filled-closed"
              width={24}
              height={24}
            />
          </div>
        )}

        <CSSTransition
          classNames={transitionCNs(type)}
          mountOnEnter
          in={isMounted}
          appear
          unmountOnExit
          onExited={onClose}
          timeout={{
            appear: type === 'slide' ? 500 : 200,
            exit: type === 'slide' ? 500 : 200,
          }}
        >
          <PopupLayoutBody
            title={title}
            className={className}
            variant={variant}
            type={type}
            hasClose={hasClose}
            onClose={() => setIsMounted(false)}
          >
            {typeof children === 'function' ? children(childProps) : children}
          </PopupLayoutBody>
        </CSSTransition>
      </div>
    </FocusLock>
  );
}

const PopupLayoutBody: FC<{
  variant: PopupLayoutVariant;
  type: PopupLayoutType;
  className?: string;
  title?: string;
  hasClose?: boolean;
  onClose?: () => void;
}> = ({ children, variant, type, className, hasClose, title, onClose }) => {
  return (
    <div
      className={cx(
        styles.body,
        {
          [styles.bodyModal]: type === 'modal',
          [styles.bodySlide]: type === 'slide',
          [styles.bodyNoPadding]:
            variant === PopupLayoutVariant.NoPadding ||
            variant === PopupLayoutVariant.Generic,
          [styles.bodyHeadless]: variant === PopupLayoutVariant.Headless,
        },
        className
      )}
    >
      {hasClose && (
        <button className={styles.close} onClick={onClose}>
          <Icon className={styles.closeIcon} name="close" />
        </button>
      )}
      {title && <h2 className={styles.headingTitle}>{title}</h2>}
      {variant === PopupLayoutVariant.Generic ? (
        <div className={styles.bodyGrid}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

const PopupLayoutSection: FC<{
  isScrollable: boolean;
}> = ({ children, isScrollable }) => {
  return (
    <section className={styles.section} data-scroll={isScrollable}>
      {children}
    </section>
  );
};

PopupLayout.Section = PopupLayoutSection;

export enum PopupLayoutFooterVariant {
  JustifyEndButton = 'default',
  SpaceBetweenButtons = 'spaceBetween',
  Center = 'center',
}

const PopupLayoutFooter: FC<{ variant?: PopupLayoutFooterVariant }> = ({
  children,
  variant = PopupLayoutFooterVariant.JustifyEndButton,
}) => {
  return (
    <div className={styles.footer} data-variant={variant}>
      {children}
    </div>
  );
};

PopupLayout.Footer = PopupLayoutFooter;

export enum PopupLayoutHeaderVariant {
  Center = 'center',
  Default = 'default',
  SubtitleDescription = 'subtitle-description',
}

interface PopupLayoutHeaderProps {
  title?: ReactNode;
  subtitle?: string;
  variant?: PopupLayoutHeaderVariant;
}
const PopupLayoutHeader: FC<PopupLayoutHeaderProps> = ({
  title,
  subtitle,
  children,
  variant = PopupLayoutHeaderVariant.Default,
}) => {
  return (
    <div className={styles.heading}>
      {title && (
        <div data-variant={variant} className={styles.headingContent}>
          <h2 className={styles.headingTitle}>{title}</h2>
          {subtitle && (
            <span className={styles.headingSubtitle}>{subtitle}</span>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

PopupLayout.Header = PopupLayoutHeader;

const transitionCNs = (type: PopupLayoutType) => {
  return {
    appear: styles[`popup-${type}-enter`],
    appearActive: styles[`popup-${type}-enter-active`],
    appearDone: styles[`popup-${type}-enter-done`],
    exit: styles[`popup-${type}-exit`],
    exitActive: styles[`popup-${type}-exit-active`],
    exitDone: styles[`popup-${type}-exit-done`],
  };
};

export default PopupLayout;
