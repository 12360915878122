import { useCallback, useState, VFC } from 'react';
import FormInput from 'shared-components/src/components/FormInput';
import Button from 'shared-components/src/components/Button';
import PopupLayout from 'shared-components/src/components/PopupLayout';

import styles from './RequiredEmailScreen.module.css';

interface RequiredEmailPopupProps {
  onSubmit: (passcode: string) => void;
  hasError: boolean;
}

const RequiredEmailPopup: VFC<RequiredEmailPopupProps> = ({
  onSubmit,
  hasError,
}) => {
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(email);
    },
    [onSubmit, email]
  );

  const handleEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.root}
      data-testid="demo-required-email-form"
    >
      <div className={styles.title}>Enter Your Email</div>
      <div className={styles.description}>
        Enter your email address to view the demo.
      </div>
      <FormInput
        data-testid="demo-required-email-form-input"
        autoFocus
        type="email"
        value={email}
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
        onChange={handleEmailChange}
        error={hasError ? 'Invalid email provided' : ''}
      />
      <Button
        data-testid="demo-required-email-form-submit"
        disabled={!email}
        type="submit"
        variant="purple"
        className={styles.submitButton}
      >
        Submit
      </Button>
    </form>
  );
};

type RequiredEmailScreenProps = {
  onSubmit: (passcode: string) => void;
  hasError: boolean;
};

const RequiredEmailScreen: VFC<RequiredEmailScreenProps> = (props) => {
  return (
    <PopupLayout hasClose={false}>
      <RequiredEmailPopup {...props} />
    </PopupLayout>
  );
};

export default RequiredEmailScreen;
