import { VFC } from 'react';

import { ScreenBreakpointMobile } from '../../features/ui/ui.constants';

/**
 * In some pages we need to add responsiveness. To do this we add <style> with `html`.
 *
 * How to use with react-helmet:
 * ```
 * <Helmet>{ResponsiveRootStyle({})}</Helmet>
 * ```
 */
const ResponsiveRootStyle: VFC<{
  /**
   * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/83001365/DRAFT+Responsive#Global-styles Docs}
   */
  isResponsiveDemoPlayerEnabled: boolean;
}> = ({ isResponsiveDemoPlayerEnabled }) => {
  return (
    <style type="text/css">
      {`
      @media (${
        isResponsiveDemoPlayerEnabled
          ? `min-width: ${ScreenBreakpointMobile + 1}px` // we have responsive design for <= 600px
          : 'max-width: 1230px'
      }) {
          @media only screen and (min-aspect-ratio: 1/1) { 
          html {
            font-size: max(4px, 1.3vw);
          }
        }
      }
       @media only screen and (max-aspect-ratio: 1/1){
        html {
          font-size: 16px;
        }
       }

      @media (min-width: 1231px) and (max-width: 1900px) {
        html {
          font-size: 16px;
        }
      }
    
      @media (min-width: 1901px){
        html {
          font-size: 20px;
          font-size: min(20px, 1vw);
        }
      }
    `}
    </style>
  );
};

export default ResponsiveRootStyle;
