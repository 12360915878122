import { schema } from 'normalizr';

import { widgetSchema } from '../widgets/widgets.schemas';

export const flowSchema = new schema.Entity(
  'flows',
  {
    widgets: [widgetSchema],
  },
  {
    processStrategy: (value, parent) => {
      return { ...value, projectId: parent.projectId };
    },
  }
);
