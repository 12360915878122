import { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import ResponsiveRootStyle from 'shared-components/src/components/ResponsiveRootStyle';
import {
  CreateRequestErrorResponse,
  isRequestErrorResponse,
} from 'shared-components/src/api/utils/createRequest';
import { isApiErrorResponse } from 'shared-components/src/api/utils/isApiError';
import axios from 'axios';

import {
  getSharedProject,
  GetSharedProjectResponse,
} from 'container/PublicDemoPage/api/project.api';
import PassCodeScreen from 'container/PublicDemoPage/PasscodeScreen';
import DemoNotFound from 'container/PublicDemoPage/DemoNotFound';
import RequiredEmailScreen from 'container/PublicDemoPage/RequiredEmailScreen';
import { getAbsoluteUrl } from 'utils/getAbsoluteUrl';
import {
  PASSCODE_STORAGE_PREFIX,
  REQUIRED_EMAIL_STORAGE_PREFIX,
} from 'container/PublicDemoPage/PublicDemoPage.machine';
import { ProjectErrorCode } from 'container/PublicDemoPage/PublicDemoPage.constants';
import {
  getCookiesEmailRequired,
  getCookiesPasscode,
  setCookiesEmailRequired,
  setCookiesPasscode,
} from 'container/PublicDemoPage/PublicDemoPage.utils';
import HeadCustomFont from 'components/HeadCustomFont';
import { isValidId } from 'utils/isValidId';
import { getIpAddress } from 'utils/getUserIpAddress';

const PublicDemoPage = dynamic(() => import('container/PublicDemoPage'), {
  ssr: true,
});

interface DemoProps {
  demoId: string;
  responseData: string | null;
  baseUrl: string;
}

const Demo = ({ demoId, responseData, baseUrl }: DemoProps) => {
  const response = JSON.parse(responseData || '{}');

  const [sharedResponse, setSharedResponse] = useState<{
    data: GetSharedProjectResponse | CreateRequestErrorResponse;
  }>(response);
  const [isInvalidPassword, setInvalidPassword] = useState<boolean>(false);
  const [isInvalidEmail, setInvalidEmail] = useState<boolean>(false);

  const originalErrorResponse = isRequestErrorResponse(sharedResponse.data)
    ? sharedResponse.data.error
    : null;

  const isPasswordProtected =
    isApiErrorResponse(originalErrorResponse) &&
    originalErrorResponse.error?.code === ProjectErrorCode.PROJECT_PROTECTED;

  const isPasswordExpired =
    isApiErrorResponse(originalErrorResponse) &&
    originalErrorResponse?.error?.code === ProjectErrorCode.PROJECT_EXPIRED;

  const isEmailProtected =
    isApiErrorResponse(originalErrorResponse) &&
    originalErrorResponse?.error?.code ===
      ProjectErrorCode.PROJECT_LINK_EMAIL_REQUIRED;

  const handlePasswordSubmit = useCallback(
    async (password: string) => {
      const hashedPassword = btoa(password);
      try {
        const email = getCookiesEmailRequired(demoId);

        const response = await getSharedProject({
          demoId,
          password: hashedPassword,
          requiredEmail: email,
          origin: baseUrl, // TODO: set to https://app.storylane.io to debug production
        });

        setSharedResponse({
          data: response.data,
        });

        setInvalidPassword(false);
        setCookiesPasscode(demoId, hashedPassword);
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          if (
            error.response?.data.error.code ===
            ProjectErrorCode.PROJECT_PROTECTED
          ) {
            setInvalidPassword(true);
          }

          // password is correct but email is required
          if (
            error.response?.data.error.code ===
            ProjectErrorCode.PROJECT_LINK_EMAIL_REQUIRED
          ) {
            setSharedResponse({
              data: {
                error: error.response.data,
              },
            });

            setCookiesPasscode(demoId, hashedPassword);
          }
        }
      }
    },
    [baseUrl, demoId]
  );

  const handleEmailSubmit = useCallback(
    async (email: string) => {
      try {
        const prevPassword = getCookiesPasscode(demoId);

        const response = await getSharedProject({
          demoId,
          requiredEmail: email,
          password: prevPassword,
          origin: baseUrl, // TODO: set to https://app.storylane.io to debug production
        });

        setSharedResponse({
          data: response.data,
        });

        setInvalidEmail(false);
        setCookiesEmailRequired(demoId, email);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (
            error.response?.data.error.code ===
            ProjectErrorCode.PROJECT_PROTECTED
          ) {
            // this is almost impossible case
            // but anyway redirect user to enter passcode again with error message
            setInvalidPassword(true);

            setSharedResponse({
              data: {
                error: error.response.data,
              },
            });
          }

          if (
            error.response?.data.error.code ===
            ProjectErrorCode.PROJECT_LINK_EMAIL_REQUIRED
          ) {
            setInvalidEmail(true);
          }
        } else {
          console.error(error);
        }
      }
    },
    [baseUrl, demoId]
  );

  if (isPasswordProtected) {
    return (
      <>
        <Head>
          {ResponsiveRootStyle({
            isResponsiveDemoPlayerEnabled: false,
          })}
        </Head>
        <HeadCustomFont font={null} />
        <PassCodeScreen
          hasError={isInvalidPassword}
          onSubmit={handlePasswordSubmit}
          onClearError={() => setInvalidPassword(false)}
        />
      </>
    );
  }

  if (isPasswordExpired) return <DemoNotFound subject="link" />;

  if (isEmailProtected) {
    return (
      <>
        <Head>
          {ResponsiveRootStyle({
            isResponsiveDemoPlayerEnabled: false,
          })}
        </Head>
        <HeadCustomFont font={null} />
        <RequiredEmailScreen
          onSubmit={handleEmailSubmit}
          hasError={isInvalidEmail}
        />
      </>
    );
  }

  // apart from password protected and expired, if there is any other error
  if (originalErrorResponse) return <DemoNotFound subject="link" />;

  return (
    <PublicDemoPage
      baseUrl={baseUrl}
      responseData={sharedResponse.data as GetSharedProjectResponse}
      demoId={demoId}
    />
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const demoId = Array.isArray(context.query.id)
    ? context.query.id[0]
    : context.query.id ?? '';

  const userAgent = context.req
    ? context.req.headers['user-agent']
    : navigator.userAgent;

  const userIP = getIpAddress(context.req);

  const absoluteUrl = getAbsoluteUrl(context.req);

  if (!isValidId(demoId)) {
    return {
      notFound: true,
      props: {
        responseData: null,
        baseUrl: absoluteUrl.origin,
        meta: {
          userAgent,
        },
      },
    };
  }

  const additionalQuery = Array.isArray(context.query.additionalQuery)
    ? context.query.additionalQuery[0]
    : context.query.additionalQuery ?? '';

  const hashedPassword =
    context.req.cookies?.[`${PASSCODE_STORAGE_PREFIX}${demoId}`];

  const hashedEmail =
    context.req.cookies?.[`${REQUIRED_EMAIL_STORAGE_PREFIX}${demoId}`];

  const passcode = hashedPassword ? decodeURIComponent(hashedPassword) : '';

  const email = hashedEmail ? decodeURIComponent(hashedEmail) : '';

  try {
    const response = await getSharedProject({
      demoId,
      password: passcode,
      requiredEmail: email,
      additionalQuery,
      userIP,
      userAgent,
      origin: absoluteUrl.origin, // TODO: set to https://app.storylane.io to debug production
    });

    return {
      props: {
        demoId,
        responseData: JSON.stringify({
          data: response.data,
        }),
        baseUrl: absoluteUrl.origin,
        meta: {
          userAgent,
        },
      },
    };
  } catch (error: any) {
    const errorResponse = error.response;
    if (errorResponse) {
      return {
        props: {
          demoId,
          responseData: JSON.stringify({
            data: {
              error: errorResponse.data,
            },
          }),
          baseUrl: absoluteUrl.origin,
          meta: {
            userAgent,
          },
        },
      };
    }
    return {
      props: {
        demoId,
        responseData: null,
        baseUrl: absoluteUrl.origin,
        meta: {
          userAgent,
        },
      },
    };
  }
};

export default Demo;
