import { AxiosRequestConfig } from 'axios';

export interface RequestConfig extends AxiosRequestConfig {
  path?: string;
}

export type CreateRequestErrorResponse = {
  error: unknown;
};

export const isRequestErrorResponse = (
  data: unknown
): data is CreateRequestErrorResponse => {
  return typeof data === 'object' && data !== null && 'error' in data;
};
