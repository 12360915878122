import axios, { AxiosTransformer } from 'axios';
import {
  CreateRequestErrorResponse,
  isRequestErrorResponse,
} from 'shared-components/src/api/utils/createRequest';
import { FeatureFlags } from 'shared-components/src/features/featureFlags/featureFlags.types';
import { SubstitutionValue } from 'shared-components/src/features/substitution/substitution.types';
import { normalize, NormalizedSchema, schema } from 'normalizr';
import { Flowlist } from 'shared-components/src/features/flowlist/flowlist.types';
import { FlowId } from 'shared-components/src/features/flow/flow.types';
import {
  AnyWidget,
  BaseWidget,
  WidgetId,
} from 'shared-components/src/features/widgets/widgets.types';
import {
  PageId,
  ProjectPage,
} from 'shared-components/src/features/projectPages/projectPages.types';
import {
  PageLinkId,
  PageLinkWithId,
} from 'shared-components/src/features/pageLink/pageLink.types';
import {
  ProjectImageToken,
  ProjectImageTokenId,
} from 'shared-components/src/features/projectImageToken/projectImageToken.types';
import { DisplayConfigType } from 'shared-components/src/features/project/project.types';
import { ProjectKind } from 'shared-components/src/features/project/project.constants';
import { WhiteLabelSettings } from 'shared-components/src/features/whitelabel/whitelabel.types';
import { flowSchema } from 'shared-components/src/features/flow/flow.schemas';

import { apiInstance } from 'api';
import { PublishedFormConfig } from '../LeadForm/LeadForm.types';

export const publishedProjectSchema = new schema.Entity('projects', {
  flows: [flowSchema],
});

export type GetSharedProjectResponse = NormalizedSchema<
  {
    projects: Record<string, NormalizedPublishedProject>;
    flows?: NormalizedPublishedFlows;
    widgets?: NormalizedPublishedWidgets;
  },
  {
    form: PublishedFormConfig;
    project: ProjectId;
    substitutions: SubstitutionValue[];
  }
>;

export type SharedProjectResponse = {
  project: PublishedProject;
  form?: PublishedFormConfig;
  substitutions: SubstitutionValue[];
};

interface GetSharedProjectParams {
  demoId: string;
  password?: string;
  requiredEmail?: string;
  additionalQuery?: string;
  userIP?: string;
  userAgent?: string;
  origin?: string;
}

export const getSharedProject = ({
  demoId,
  password,
  requiredEmail,
  additionalQuery,
  userIP,
  userAgent,
  origin,
}: GetSharedProjectParams): Promise<{
  data: GetSharedProjectResponse | CreateRequestErrorResponse;
}> => {
  const url =
    `shared/projects/${demoId}` +
    (requiredEmail ? `?email=${encodeURIComponent(requiredEmail)}` : '');

  return apiInstance.get(url, {
    headers: {
      Authorization: password ? `Bearer ${password}` : '',
      Origin: origin,
      additionalQuery,
      'X-Forwarded-For': userIP || '',
      'X-User-Agent': userAgent || '',
    },
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosTransformer[]),
      (data: SharedProjectResponse | CreateRequestErrorResponse) => {
        if (isRequestErrorResponse(data)) {
          return data;
        }
        return normalize(data, { project: publishedProjectSchema });
      },
    ],
  });
};

export type ProjectId = string;
export type CompanyId = string;

export interface PublishedProject {
  id: ProjectId;
  name: string;
  url: string;
  company_id: CompanyId;
  pages_count: number;
  start_page_id: PageId;
  pages: PublishedProjectPage[];
  page_links: Record<PageLinkId, PageLinkWithId>;
  image_tokens: Record<ProjectImageTokenId, ProjectImageToken>;
  widgets_count: number;
  display_config: DisplayConfigType;
  flows: { id: FlowId; name: string; widgets: AnyWidget[] }[];
  flows_count: number;
  flowlists: Omit<Flowlist, 'projectId' | 'name'>[];
  kind: ProjectKind;
  whitelabel: WhiteLabelSettings;
  translation_animation_enabled: boolean;
  lead_cookie_tracking_enabled: boolean;
  /**
   * @see {@link https://storylane.atlassian.net/l/cp/LVs4C416 Docs}
   */
  scripts: {
    id: string;
    position: 'head' | 'body' | 'iframe_head' | 'iframe_body';
    content: string;
  }[];
  feature_flags: FeatureFlags;
  ga_measurement_id: string | null;
  gtm_container_id: string | null;
}

export type PublishedProjectPage = Omit<
  ProjectPage,
  | 'status'
  | 'project_id'
  | 'user_id'
  | 'widgets_count'
  | 'index'
  | 'original_file_url'
>;

export interface NormalizedPublishedProject
  extends Omit<PublishedProject, 'flows' | 'flowlists'> {
  flows: FlowId[];
  flowlists: Flowlist[];
}

export type NormalizedPublishedFlows = Record<
  FlowId,
  {
    id: FlowId;
    name: string;
    widgets: WidgetId[];
  }
>;
export type NormalizedPublishedWidgets = Record<WidgetId, BaseWidget>;
