export enum PopupLayoutType {
  Modal = 'modal',
  /**
   * @deprecated It was introduced long time back and not actively used.
   */
  Slide = 'slide',
}

export enum PopupLayoutVariant {
  Default = 'default',
  NoPadding = 'noPadding',
  Headless = 'headless',
  Generic = 'generic', // synced with design system
}
