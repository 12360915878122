import { useCallback, useEffect, useState, VFC } from 'react';
import Button from 'shared-components/src/components/Button';
import PopupLayout from 'shared-components/src/components/PopupLayout';
import PasswordFormInput from 'shared-components/src/components/PasswordFormInput';

import styles from './PasscodeScreen.module.css';

interface PasscodePopupProps {
  onSubmit: (passcode: string) => void;
  hasError: boolean;
  onClearError: () => void;
}

const PasscodePopup: VFC<PasscodePopupProps> = ({
  onSubmit,
  hasError,
  onClearError,
}) => {
  const [passcode, setPasscode] = useState('');

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(passcode);
    },
    [onSubmit, passcode]
  );

  // Clear field value if error occurs
  useEffect(() => {
    if (hasError) {
      setPasscode('');
    }
  }, [hasError]);

  const handlePasscodeChange = useCallback(
    (event) => {
      setPasscode(event.target.value);
      onClearError();
    },
    [onClearError]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.root}
      data-testid="demo-passcode-form"
    >
      <div className={styles.title}>Enter Passcode</div>
      <div className={styles.description}>
        Please enter the passcode below to view the demo
      </div>
      <PasswordFormInput
        data-testid="demo-passcode-form-input"
        placeholder="••••••"
        value={passcode}
        onChange={handlePasscodeChange}
        error={hasError ? 'Incorrect passcode' : ''}
      />
      <Button
        data-testid="demo-passcode-form-submit"
        type="submit"
        variant="purple"
        className={styles.submitButton}
      >
        Submit
      </Button>
    </form>
  );
};

type PasscodeScreenProps = {
  onSubmit: (passcode: string) => void;
  hasError: boolean;
  onClearError: () => void;
};

const PasscodeScreen: VFC<PasscodeScreenProps> = (props) => {
  return (
    <PopupLayout hasClose={false}>
      <PasscodePopup {...props} />
    </PopupLayout>
  );
};

export default PasscodeScreen;
