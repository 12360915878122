import {
  useEffect,
  useRef,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
} from 'react';
import cx from 'classnames';
import autoAnimate from '@formkit/auto-animate';

import styles from './FormInput.module.css';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

export interface FormInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  beginIcon?: string;
  onBeginIconClick?: () => void;
  endIcon?: string;
  endIconClassName?: string;
  onEndIconClick?: () => void;
  label?: React.ReactNode;
  error?: string;
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  className?: string;
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  inputClassName?: string;
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  errorClassName?: string;
  fullWidth?: boolean;
  variant?: 'default' | 'filled';
  labelTooltipText?: string;
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>((props, ref) => {
  const {
    error,
    className,
    beginIcon,
    onBeginIconClick,
    endIcon,
    onEndIconClick,
    endIconClassName,
    id,
    label,
    inputClassName,
    errorClassName,
    fullWidth,
    variant = 'default',
    labelTooltipText,
    ...restProps
  } = props;

  const parent = useRef<HTMLDivElement>(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <div
      ref={parent}
      className={cx(styles.root, className, {
        [styles.rootFullWidth]: fullWidth,
      })}
    >
      {label && (
        <div className={styles.tooltipWrapper}>
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>

          {labelTooltipText && (
            <Tooltip triggerOn="hover" placement="top">
              <Tooltip.Trigger>
                <Icon name="info" width={14} height={14} />
              </Tooltip.Trigger>
              <Tooltip.Content variant="dark">
                <span
                  className={styles.tooltipContent}
                  dangerouslySetInnerHTML={{
                    __html: labelTooltipText,
                  }}
                />
              </Tooltip.Content>
            </Tooltip>
          )}
        </div>
      )}

      <span className={styles.inputWrap}>
        {beginIcon && (
          <Icon
            name={beginIcon}
            className={styles.icon}
            onClick={onBeginIconClick}
          />
        )}
        <input
          ref={ref}
          id={id}
          className={cx(styles.input, inputClassName, {
            [styles.inputWithBeginIcon]: beginIcon,
            [styles.inputWithEndIcon]: endIcon,
            [styles.inputFilledVariant]: variant === 'filled',
          })}
          {...restProps}
        />
        {endIcon && (
          <Icon
            name={endIcon}
            className={cx(styles.trailingIcon, endIconClassName)}
            onClick={onEndIconClick}
          />
        )}
      </span>

      {error && (
        <span className={cx(styles.error, errorClassName)}>{error}</span>
      )}
    </div>
  );
});

FormInput.displayName = 'FormInput';

export default FormInput;
