import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export enum ApiErrorCode {
  ResourceValidationError = 'resource_validation_errors',
}

export interface ApiError {
  [index: string]: unknown;
  title: string;
  description: string;
  status: number;
  code: string;
  details?: Record<string, string[]>;
}

export const isApiError = (payload: unknown): payload is ApiError =>
  Boolean(payload) &&
  payload !== null &&
  typeof payload === 'object' &&
  'code' in (payload as never);

export const isApiErrorResponse = (
  response: unknown
): response is { error: ApiError } =>
  typeof response === 'object' &&
  response !== null &&
  'error' in response &&
  isApiError((response as { error: unknown }).error);

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (error as any).message === 'string'
  );
}
