import { schema } from 'normalizr';

import { DraftNewWidget } from './widgets.types';
import { createWidgetConfig, upgradeWidgetVersion } from './widgets.utils';

export const widgetSchema = new schema.Entity(
  'widgets',
  {},
  {
    processStrategy: (value, parent) => {
      return {
        ...createWidgetConfig(
          upgradeWidgetVersion(value) as unknown as DraftNewWidget
        ),
        projectId: parent.projectId ?? value.projectId,
        flowId: parent.id ?? value.flowId,
      };
    },
  }
);
