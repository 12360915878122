// Generated by Chat GPT, so you know who to blame if there is an issue =D
export const enum HTTP_STATUS_CODES {
  HTTP_100_CONTINUE = 100,
  HTTP_101_SWITCHING_PROTOCOLS = 101,
  HTTP_102_PROCESSING = 102, // WebDAV; RFC 2518
  HTTP_103_EARLY_HINTS = 103, // RFC 8297

  HTTP_200_OK = 200,
  HTTP_201_CREATED = 201,
  HTTP_202_ACCEPTED = 202,
  HTTP_203_NON_AUTHORITATIVE_INFORMATION = 203,
  HTTP_204_NO_CONTENT = 204,
  HTTP_205_RESET_CONTENT = 205,
  HTTP_206_PARTIAL_CONTENT = 206,
  HTTP_207_MULTI_STATUS = 207, // WebDAV; RFC 4918
  HTTP_208_ALREADY_REPORTED = 208, // WebDAV; RFC 5842
  HTTP_226_IM_USED = 226, // RFC 3229

  HTTP_300_MULTIPLE_CHOICES = 300,
  HTTP_301_MOVED_PERMANENTLY = 301,
  HTTP_302_FOUND = 302,
  HTTP_303_SEE_OTHER = 303,
  HTTP_304_NOT_MODIFIED = 304,
  HTTP_305_USE_PROXY = 305,
  HTTP_307_TEMPORARY_REDIRECT = 307,
  HTTP_308_PERMANENT_REDIRECT = 308, // RFC 7538

  HTTP_400_BAD_REQUEST = 400,
  HTTP_401_UNAUTHORIZED = 401,
  HTTP_402_PAYMENT_REQUIRED = 402, // // Description. The HTTP 402 status code indicates that the client must make a payment to access the requested resource. It is typically used in situations where the server requires payment before granting access to the content or service.
  HTTP_403_FORBIDDEN = 403,
  HTTP_404_NOT_FOUND = 404,
  HTTP_405_METHOD_NOT_ALLOWED = 405,
  HTTP_406_NOT_ACCEPTABLE = 406,
  HTTP_407_PROXY_AUTHENTICATION_REQUIRED = 407,
  HTTP_408_REQUEST_TIMEOUT = 408,
  HTTP_409_CONFLICT = 409,
  HTTP_410_GONE = 410,
  HTTP_411_LENGTH_REQUIRED = 411,
  HTTP_412_PRECONDITION_FAILED = 412,
  HTTP_413_PAYLOAD_TOO_LARGE = 413,
  HTTP_414_URI_TOO_LONG = 414,
  HTTP_415_UNSUPPORTED_MEDIA_TYPE = 415,
  HTTP_416_RANGE_NOT_SATISFIABLE = 416,
  HTTP_417_EXPECTATION_FAILED = 417,
  HTTP_418_I_AM_A_TEAPOT = 418, // RFC 2324
  HTTP_421_MISDIRECTED_REQUEST = 421, // RFC 7540
  HTTP_422_UNPROCESSABLE_ENTITY = 422, // WebDAV; RFC 4918
  HTTP_423_LOCKED = 423, // WebDAV; RFC 4918
  HTTP_424_FAILED_DEPENDENCY = 424, // WebDAV; RFC 4918
  HTTP_425_TOO_EARLY = 425, // RFC 8470
  HTTP_426_UPGRADE_REQUIRED = 426, // RFC 7231
  HTTP_428_PRECONDITION_REQUIRED = 428, // RFC 6585
  HTTP_429_TOO_MANY_REQUESTS = 429, // RFC 6585
  HTTP_431_REQUEST_HEADER_FIELDS_TOO_LARGE = 431, // RFC 6585
  HTTP_451_UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  HTTP_500_INTERNAL_SERVER_ERROR = 500,
  HTTP_501_NOT_IMPLEMENTED = 501,
  HTTP_502_BAD_GATEWAY = 502,
  HTTP_503_SERVICE_UNAVAILABLE = 503,
  HTTP_504_GATEWAY_TIMEOUT = 504,
  HTTP_505_VERSION_NOT_SUPPORTED = 505,
  HTTP_506_VARIANT_ALSO_NEGOTIATES = 506, // RFC 2295
  HTTP_507_INSUFFICIENT_STORAGE = 507, // WebDAV; RFC 4918
  HTTP_508_LOOP_DETECTED = 508, // WebDAV; RFC 5842
  HTTP_510_NOT_EXTENDED = 510, // RFC 2774
  HTTP_511_NETWORK_AUTHENTICATION_REQUIRED = 511, // RFC 6585
}
